<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Dictionary Management" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end import mb-1">
      <b-button pill variant="outline-info">
        <a href="https://docs.google.com/spreadsheets/d/1P5fHWJ8l8ye_QK8aiJrjTaD1CKTFwD5LCZxoS1jttuA/edit?gid=0#gid=0"
          target="_blank">?</a>
      </b-button>
      <label for="upload" class="btn btn-import btn-primary">
        <span>IMPORT</span>
        <input type="file" id="upload" ref="files" style="display: none" @change="handleFilesUpload">
      </label>
      <p class="rows-uploaded">{{ excelData.length + ' rows uploaded' }}</p>
      <b-button v-if="isReadyImport" variant="success" class="btn-add mr-1" size="sm"
        @click="updateLoadData">SAVE</b-button>
      <b-button v-if="isReadyImport" variant="success" class="btn-add" size="sm" @click="replaceData">REPLACE</b-button>
    </div>
    <div class="d-flex align-items-center">
      <form-generator :model="filter" :schema="filter_schema" />
    </div>
    <data-create-popup title="Create Word" :schema="create_schema" :default-data="default_data"
      :create-data-fn="createItem" @create="$refs.group_list.getList()" />
    <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getList"
      :update_schema="update_schema" :edit-row-fn="updateItem" :delete-row-fn="deleteItem" />
  </div>
</template>

<script>
import service from "../service";
import default_data from "../default_data";
import XLSX from 'xlsx'
const VALID_FILE_EXTENSIONS = 'xlsx'
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Word', field: 'word', validate: { required: true } },
    ]
  },
];
const update_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Word', field: 'word', validate: { required: true } },
    ]
  },
];
const fields = [
  { label: 'Word', field: 'word' },
];
export default {

  data() {
    return {
      courses: [],
      default_data: { ...default_data },
      create_schema,
      fields,
      update_schema,
      excelData: [],
      isReadyImport: false,
      filter: {},
      filter_schema: [
        {
          cols: 12, fields: [{ field: 'word_key', label: 'Word' }]
        },
      ],
    };
  },
  watch: {
    filter: {
      handler() {
        if (this.filter.word_key) {
          this.filter.word_key = this.filter.word_key.trim();
          this.filter.word = { $regex: this.filter.word_key, "$options": 'i' }
        }
        else {
          delete this.filter.word
        }
        this.$refs.group_list.getList();
      },
      deep: true
    }
  },
  methods: {
    async getList({ limit, page }) {
      let query = JSON.parse(JSON.stringify(this.filter));
      delete query.word_key;
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    async updateItem(row) {
      await service.update({ data: JSON.stringify(row) });
    },
    async deleteItem({ _id }) {
      await service.delete({ id: _id });
    },
    handleFilesUpload(e) {
      var input = e.target;
      var reader = new FileReader();
      if (!input.files[0] || !this.checkValidFileExtension(input.files[0].name)) {
        return this.$store.dispatch("pushErrorNotify", {
          text: "File upload is invalid!",
        })
      }
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: 'binary' });
        wb.SheetNames.forEach((sheetName) => {
          var rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
          this.excelData = rowObj
        })
      };
      reader.readAsBinaryString(input.files[0]);
      this.isReadyImport = true;
    },

    checkValidFileExtension(fileName) {
      if (!fileName) { return false }
      return fileName.substr(fileName.length - VALID_FILE_EXTENSIONS.length, VALID_FILE_EXTENSIONS.length).toLowerCase() === VALID_FILE_EXTENSIONS
    },

    async updateLoadData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          _id: usr._id || null,
          word: usr['Word'],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "data is empty!",
        })
      }

      let res = await service.import(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Import Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    },
    async replaceData() {
      this.isLoading = true
      const dataUpload = this.excelData.map(usr => {
        return {
          word: usr['Word'],
        }
      })
      if (!dataUpload.length) {
        this.isLoading = false
        return this.$store.dispatch("pushErrorNotify", {
          text: "data is empty!",
        })
      }

      let res = await service.replace(dataUpload);
      this.isLoading = false
      if (res) {
        this.$store.dispatch("pushSuccessNotify", { text: "Replace Successfully!" });
        this.excelData = [];
      }
      this.$router.go()
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-import {
  margin-left: 5px;
  display: table;
  margin-bottom: 0;

  span {
    display: table-cell;
  }
}

.rows-uploaded {
  margin: auto 5px;
  padding: 10px 5px;
  border: solid 1px;
  border-radius: 10px;
  font-weight: 800;
}
</style>
